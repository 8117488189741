import React from 'react'

function MissionVisionEn() {
  return (
    <div class='privacy-html'>
        <h1>Mission</h1>
        <p>At NeoArts, our mission is to provide innovative and customized technological solutions that drive the success and digital transformation of our clients. We are committed to deeply understanding the needs of each client and delivering web design, software development, information systems, and digital marketing services that exceed their expectations. We strive to establish long-term relationships based on trust, efficiency, and excellence in service, leveraging cutting-edge technologies and agile methodologies to ensure the highest quality in everything we do.</p>
        <br/>
        <h1>Vision</h1>
        <p>Our vision at NeoArts is to be recognized as leaders in the technology solutions industry, standing out for our innovation, work ethic, and commitment to customer satisfaction. We aim to be the preferred strategic partner for companies seeking to excel in an ever-evolving digital world. We aspire to be pioneers in creating captivating web experiences, developing state-of-the-art custom software, implementing robust information systems, and executing effective digital marketing strategies. With our customer-centric approach and dedication to excellence, we are determined to lead our clients towards digital success, driving growth and innovation every step of the way.</p>
    </div>
  )
}

export default MissionVisionEn