import React from 'react'

function MissionVisionEs() {
  return (
    <div class='privacy-html'>
        <h1>Misión</h1>
        <p>En NeoArts, nuestra misión es proporcionar soluciones tecnológicas innovadoras y personalizadas que impulsen el éxito y la transformación digital de nuestros clientes. Nos comprometemos a entender profundamente las necesidades de cada cliente y a ofrecer servicios de diseño web, desarrollo de software, sistemas de información y marketing digital que superen sus expectativas. Nos esforzamos por establecer relaciones a largo plazo basadas en la confianza, la eficiencia y la excelencia en el servicio, aprovechando tecnologías de vanguardia y metodologías ágiles para garantizar la más alta calidad en todo lo que hacemos.</p>
        <br/>
        <h1>Visión</h1>
        <p>Nuestra visión en NeoArts es ser reconocidos como líderes en la industria de soluciones tecnológicas, destacando por nuestra innovación, ética de trabajo y compromiso con la satisfacción del cliente. Nos esforzamos por ser el socio estratégico preferido para empresas que buscan sobresalir en un mundo digital en constante evolución. Aspiramos a ser pioneros en la creación de experiencias web cautivadoras, el desarrollo de software personalizado de última generación, la implementación de sistemas de información robustos y la ejecución de estrategias de marketing digital efectivas. Con nuestro enfoque centrado en el cliente y nuestra dedicación a la excelencia, estamos decididos a llevar a nuestros clientes hacia el éxito digital, impulsando el crecimiento y la innovación en cada paso del camino.</p>
    </div>
  )
}

export default MissionVisionEs